import React from 'react'

import * as styles from '../custom.styles.module.css'


const index = () => {
    return (
        <>

            <section className={styles.pageContainer}>
                <div className={styles.pageInnerCo}>

                    <div className={styles.videoWrapper}>
                        <video
                            className={styles.fullscreenVideo}
                            src="https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/videos/promo2C-vGhi8GeAyqaKb0xWZyrJpsE2AEt1z6.mp4"
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    </div>


                </div>

                <div className={styles.textContainer}>
                    <p className={styles.textShowcase}>
                        Together with your vision and our expertise, we elevate your brand’s entire visual experience. Whether through immersive 3D animations, dynamic 2D videos, or engaging live-action footage, we bring every idea to life with creativity, precision, and passion. With our comprehensive skills and industry experience, we transform your concepts into captivating realities that resonate with your audience.
                    </p>
                </div>
            </section>


        </>
    )
}

export default index